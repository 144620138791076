import { FieldValues } from "react-hook-form";
import config from "../../config";
import { handleErrors } from "../../js/helpers/handleErrors";
import { store } from "../../store";
import { EResultType, EVerificationJournalMessage } from "../../types";
import { ELoadingActions, setLoadingStore } from "../loading";
import { addVerificationJournalData } from "./addVerificationJournalData";

export const storeVerificationDataResults = async (
    verificationToken: string|null, 
    data: FieldValues
): Promise<null> => {
    if(!verificationToken) {
        return null
    }

    store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_VERIFICATION, true))

    let headers = {
        'Content-Type': 'application/json'
    }

    const faceImage = store.getState().stateStore.faceFileName
    const cardImages = store.getState().stateStore.cardSidesData
    const similarity = store.getState().stateStore.similarity

    const images: {
        [key: string]: string
    } = {}

    if(faceImage) {
        images['faceImage'] = faceImage
    }

    if(cardImages) {
        cardImages.forEach((cardImage, index) => {
            if(cardImage.filename) {
                images[cardImage.sideType] = cardImage.filename
            }
        })
    }

    await addVerificationJournalData(
        verificationToken, 
        EVerificationJournalMessage.verificationEnd, 
        {
            result: data
        },
        images
    )

    const iframeData = {
        result: data,
        similarity: similarity,
    }

    return fetch(`${config.api.url}/verifications/${verificationToken}/result`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            data: {
                similarity: similarity,
                data: data,
                faceImage: faceImage,
                cardImages: cardImages
            }
        })
    })
        .then(handleErrors)
        .then(res => res.json())
        .then((res) => {
            window.parent.postMessage({
                type: EResultType.SUCCESS,
                data: iframeData
            }, "*")

            console.log(iframeData)

            return null
        })
        .catch((error: any)  => {
            console.log(error)
            return null
        })
}