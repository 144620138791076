import config, { ECardBackendType, ECardType } from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';
import { setLoadingStore, ELoadingActions } from '../loading';
import { addFloatingNotification } from '../notifications';
import { logError } from '../../js/helpers/logger';
import { ECardSideType } from '../../types';
import { t } from '@lingui/macro';

interface IUploadSideIDResponse {
    fileName: string,
    ssdDescriptor: Float32Array|null,
    facePhoto: string|null
    card_type: string
    confidence: {[key: string]: number},
    detectedCard: ECardType|null,
    detectedSide: ECardSideType|null,
    detectedBackendType: ECardBackendType|null
}

const uploadCard = async (
    fileStr: string, 
    fileExtension: string, 
    sideType: ECardSideType,
    selectedCard: ECardType,
    descriptor?: any
): Promise<IUploadSideIDResponse|null> => {
    store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_UPLOAD_FILE, true))

    let headers = {
        'Content-Type': 'application/json'
    }

    return fetch(config.api.filesUrl + '/upload/side', {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            isBase64: true,
            file: fileStr,
            sideType: sideType,
            selectedCard: selectedCard,
            descriptor: descriptor,
            fileExtension: fileExtension,
        }),
    })
        .then(handleErrors)
        .then(res => res.json())
        .then(res => {
            store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_UPLOAD_FILE, false))

            if (res.isFace) {
                if (!res.ssdDescriptor) {
                    store.dispatch(addFloatingNotification({
                        text: t`Nepodarilo sa detegovať tvár. Skúste nahrať nový obrázok s lepším zaostrením alebo osvetlením.`,
                        type: 'error',
                        showBell: true
                    }))
                    
                    return null
                }

                return {
                    fileName: res.name, 
                    ssdDescriptor: Float32Array.from(Object.values(res.ssdDescriptor)),
                    facePhoto: res.facePhoto,
                    card_type: res.card_type,
                    confidence: res.confidence,
                    detectedCard: res.detectedCard,
                    detectedSide: res.detectedSide,
                    detectedBackendType: res.detectedBackendType
                }
            } else {
                if (!res.name) {
                    store.dispatch(addFloatingNotification({
                        text: t`Nepodarilo sa detegovať občiansky preukaz. Skúste nahrať nový obrázok s lepším zaostrením prípadne osvetlením.`,
                        type: 'error',
                        showBell: true
                    }))
                    
                    return null
                }

                return {
                    fileName: res.name, 
                    ssdDescriptor: null,
                    facePhoto: null,
                    card_type: res.card_type,
                    confidence: res.confidence,
                    detectedCard: res.detectedCard,
                    detectedSide: res.detectedSide,
                    detectedBackendType: res.detectedBackendType
                }
            }
        })
        .catch((error: any)  => {
            store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_UPLOAD_FILE, false))
            store.dispatch(addFloatingNotification({
                text: t`Nepodarilo sa nahrať súbor na server.`,
                type: 'error',
                showBell: true
            }))

            logError('Could not upload side', error)
            return null
        });
}

export default uploadCard
