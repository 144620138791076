import React from 'react';
import { AnimatePresence, Variants, motion } from 'framer-motion';

interface IProps {
    children?: React.ReactNode
    type: 'horizontal_left'|'horizontal_right'|'vertical_top'|'vertical_bottom'|'fade'|'custom',
    duration?: number,
    delay?: number,
    custom?: Variants,
    className?: string,
    key?: string|number,
    objType?: 'main'|'div'
}

const Animation : React.FC<IProps> = (props) => {
    let duration = props.duration?props.duration:.4

    const pageMotionHorizontalLeft: Variants = {
        initial: { opacity: 0, x: -30 },
        animate: { opacity: 1, x: 0, transition: { duration: duration, delay: props.delay } },
        exit: { opacity: 0, transition: { duration: duration } }
    }

    const pageMotionHorizontalRight: Variants = {
        initial: { opacity: 0, x: 30 },
        animate: { opacity: 1, x: 0, transition: { duration: duration, delay: props.delay } },
        exit: { opacity: 0, transition: { duration: duration } }
    }

    const pageMotionVerticalBottom: Variants = {
        initial: { opacity: 0, y: 30 },
        animate: { opacity: 1, y: 0, transition: { duration: duration, delay: props.delay } },
        exit: { opacity: 0, transition: { duration: duration } }
    }

    const pageMotionVerticalTop: Variants = {
        initial: { opacity: 0, y: -30 },
        animate: { opacity: 1, y: 0, transition: { duration: duration, delay: props.delay } },
        exit: { opacity: 0, transition: { duration: duration } }
    }

    const pageMotionFade: Variants = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: duration, delay: props.delay } },
        exit: { opacity: 0, transition: { duration: duration } }
    }

    let finalVariant: Variants = {}

    switch(props.type) {
        case 'horizontal_left':
            finalVariant = pageMotionHorizontalLeft
            break
        case 'horizontal_right':
            finalVariant = pageMotionHorizontalRight
            break
        case 'vertical_top':
            finalVariant = pageMotionVerticalTop
            break
        case 'vertical_bottom':
            finalVariant = pageMotionVerticalBottom
            break
        case 'fade':
            finalVariant = pageMotionFade
            break
        case 'custom':
            finalVariant = props.custom?props.custom:{}
    }

    if(props.objType === 'main') {
        return (
            <motion.main
                initial="initial"
                animate="animate"
                exit="exit"
                variants={finalVariant}
                className={props.className}
                key={props.key}
            >
                {props.children}
            </motion.main>
        )
    } else {
        return (
            <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={finalVariant}
                className={props.className}
                key={props.key}
            >
                {props.children}
            </motion.div>
        )
    }
}

export default Animation