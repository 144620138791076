import config from "../../config";
import { handleErrors } from "../../js/helpers/handleErrors";
import { store } from "../../store";
import { EVerificationJournalMessage } from "../../types";
import { ELoadingActions, setLoadingStore } from "../loading";
import { addFloatingNotification } from "../notifications";
import { setStateStore, EStateActions } from '../state';
import { t } from '@lingui/macro';
import { addVerificationJournalData } from "./addVerificationJournalData";

export const getVerificationByToken = async (verificationToken?: string): Promise<null> => {
    store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_VERIFICATION, true))

    let headers = {
        'Content-Type': 'application/json'
    }

    if(!verificationToken) {
        store.dispatch(setStateStore(EStateActions.SET_CURRENT_VERIFICATION, undefined))
    }

    return fetch(`${config.api.url}/verifications/${verificationToken}`, {
        method: "GET",
        headers: headers
    })
        .then(handleErrors)
        .then(res => res.json())
        .then((res) => {
            if(verificationToken) {
                addVerificationJournalData(
                    verificationToken, 
                    EVerificationJournalMessage.verificationLoaded, 
                    {
                        browser: navigator.userAgent,
                        language: navigator.language,
                        cookieEnabled: navigator.cookieEnabled,
                        platform: navigator.platform,
                        vendor: navigator.vendor
                    }
                )
            }

            if(res.status === 'INITIALIZED') {
                store.dispatch(setStateStore(EStateActions.SET_CURRENT_VERIFICATION, res))
            } else {
                store.dispatch(setStateStore(EStateActions.SET_CURRENT_VERIFICATION, undefined))
            }

            store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_VERIFICATION, false))
            return null
        })
        .catch((error: any)  => {
            console.log(error)

            store.dispatch(addFloatingNotification({
                showBell: true,
                type: 'error',
                text: t`Nepodarilo sa inicializovať overenie. Kontaktujte prosím administrátora.`
            }))

            store.dispatch(setStateStore(EStateActions.SET_CURRENT_VERIFICATION, undefined))
            store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_VERIFICATION, false))
            return null
        })
}