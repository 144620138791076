import { useAppDispatch, useAppSelector } from '../../../store';
import { EStateActions, setStateStore } from '../../../reducers/state';
import { motion } from 'framer-motion';
import { Trans } from '@lingui/macro';
import { addVerificationJournalData } from '../../../reducers/api/addVerificationJournalData';
import { EVerificationJournalMessage } from '../../../types';

interface IProps {}

export const ScreenInfoInit: React.FC<IProps> = () => {
    const dispatch = useAppDispatch()

    const agreed = useAppSelector(store => store.stateStore.agreedBegin)
    const verificationToken = useAppSelector(store => store.stateStore.verificationToken)

    return (
        <div className="block_center_vert wrapper pdt-50 pdb-50">
            <h2 className="text_center title_h1 mgt-15">
                <Trans>Biometrické overenie identity</Trans>
            </h2>
            <div className="page_content reset_margins block_center wmax560 mgt-30">
                <p style={{textAlign: 'center'}}>
                    <Trans>Aplikácia zabezpečuje biometrické overenie identity užívateľov prostredníctvom rozpoznávania tváre a osobných dokladov. Overenie užívateľov je dosiahnuté vďaka kombinácii kamery a softvéru, pričom naše riešenie zaručuje dodržanie najvyšších bezpečnostných a procesných štandardov.</Trans>
                </p>
            </div>

            <form action="/" method="post">
                <div className="box_process mgt-45">
                    <motion.div 
                        className="item_process" 
                        initial={{ 
                            opacity: 0,
                            x: -10
                        }}
                        animate={{ 
                            opacity: 1, 
                            x: 0,
                            transition: {
                                duration: .5
                            }
                        }}
                    >
                        <div className="item_process_num">1</div>

                        <div className="icon2 mid">
                            <svg viewBox="0 0 32 24" className="sicofill" xmlns="http://www.w3.org/2000/svg">
                                <use href="/img/layout/icons.svg#s_pass" />
                            </svg>   
                        </div>

                        <div className="item_process_title"><Trans>Naskenujte svoj doklad</Trans></div>
                    </motion.div>

                    <motion.div 
                        className="item_process" 
                        initial={{ 
                            opacity: 0,
                            x: -10
                        }}
                        animate={{ 
                            opacity: 1, 
                            x: 0,
                            transition: {
                                duration: 1,
                                delay: .5
                            }
                        }}
                    >
                        <div className="item_process_num">2</div>

                        <div className="icon2 mid">
                            <svg viewBox="0 0 32 32" className="sicofill" xmlns="http://www.w3.org/2000/svg">
                                <use href="/img/layout/icons.svg#s_face" />
                            </svg>   
                        </div>

                        <div className="item_process_title"><Trans>Biometrické overenie preukazu a identity</Trans></div>
                    </motion.div>
                    <motion.div 
                        className="item_process" 
                        initial={{ 
                            opacity: 0,
                            x: -10
                        }}
                        animate={{ 
                            opacity: 1, 
                            x: 0,
                            transition: {
                                duration: 1,
                                delay: 1
                            }
                        }}
                    >
                        <div className="item_process_num">3</div>

                        <div className="icon2 mid">
                            <svg viewBox="0 0 35 35" className="sicofill" xmlns="http://www.w3.org/2000/svg">
                                <use href="/img/layout/icons.svg#s_done" />
                            </svg>   
                        </div>

                        <div className="item_process_title"><Trans>Overenie údajov</Trans></div>
                    </motion.div>
                </div>

                <div className="mgt-40">
                    <label className="input_checkbox_cont">
                        <input 
                            type="checkbox" 
                            value={agreed?"1":"0"}
                            className="input_checkbox" 
                            onChange={(e) => {
                                dispatch(setStateStore(EStateActions.AGREE_BEGIN, e.target.checked))
                            }}
                        />
                        <span className="input_checkbox_text">
                            <Trans>Súhlasím s tým, aby moje biometrické informácie a kópia môjho dokladu mohli byť použité na výskum a vývoj a na zlepšenie produktu TrustSnap, ďalšie informácie o zásadách ochrany osobných údajov.</Trans>
                        </span>
                    </label>                        
                </div>

                <div className="text_center mgt-45">
                    <button 
                        type="submit" 
                        className="btn_basic bordered" 
                        disabled={agreed?false:true}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            dispatch(setStateStore(EStateActions.GO_NEXT, null))

                            addVerificationJournalData(
                                verificationToken, 
                                EVerificationJournalMessage.verificationInit, 
                                {
                                    agreed: agreed
                                }
                            )
                        }}
                    >
                        <span className="text">
                            <Trans>Začnite</Trans>
                        </span>
                    </button>                        
                </div>
            </form>
            
        </div>
    )
}
