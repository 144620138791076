import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import ButtonBack from '../functional/ButtonBack';
import DropzoneUpload from '../functional/DropzoneUpload';
import { IFile, IFileUpload, EVerificationJournalMessage } from '../../../types';
import config from '../../../config';
import { useAppSelector, useAppDispatch } from '../../../store/index';
import { EStateActions, setStateStore } from '../../../reducers/state';
import { ButtonSubmitData } from '../functional/ButtonSubmitData';
import { IconLoading } from '../icons/IconLoading';
import MainNavigation from '../global/MainNavigation';
import { t, Trans } from '@lingui/macro';
import { translateText } from '../../helpers/utils';
import { useLingui } from '@lingui/react';
import { addVerificationJournalData } from '../../../reducers/api/addVerificationJournalData';

const CONFIDENCE_VALUE = 0.98

interface IProps {
    isManual: boolean,
}

export const ScreenForm: React.FC<IProps> = ({isManual}) => {
    const dispatch = useAppDispatch()
    const lingui = useLingui()
    
    const selectedCard = useAppSelector(store => store.stateStore.selectedCard)
    const readedData = useAppSelector(store => store.stateStore.readedTexts)
    const cardSides = useAppSelector(store => store.stateStore.cardSidesData)
    const faceFilename = useAppSelector(store => store.stateStore.faceFileName)
    const loadingUpload = useAppSelector(store => store.loadingStore.loadingUploadFile)
    const verificationToken = useAppSelector(store => store.stateStore.verificationToken)

    let {
        setValue,
        formState,
        register,
        handleSubmit,
        trigger
    } = useForm({
        mode: 'all',
        shouldFocusError: true
    })

    const [uploadFiles, setUploadFiles] = useState<IFileUpload[]>([])
    const [uploadFace, setUploadFace] = useState<IFile|null>(null)
    const [confirmed, setConfirmed] = useState(false)

    const filesURL = config.assets.apiUrl

    useEffect(() => {
        addVerificationJournalData(
            verificationToken,
            isManual?
                EVerificationJournalMessage.manualStarted:
                EVerificationJournalMessage.previewResults,
            {
                readedData: readedData.map(rd => {
                    return {
                        key: rd.key,
                        value: rd.value
                    }
                }),
                faceFilename: faceFilename,
                cardSides: cardSides[0].sideType
            }
        )
    }, [verificationToken, setValue, readedData, isManual, faceFilename, cardSides])

    useEffect(() => {
        if (readedData !== null) {
            readedData.forEach(text => {
                setValue(text.key, text.value?.value)
            })
        }
    }, [setValue, readedData])

    useEffect(() => {
        setTimeout(() => {
            trigger()
        }, 200)
    }, [trigger])

    if(!selectedCard) {
        return null
    }

    return (
        <>
            <MainNavigation/>
            <div className="block_center_vert wrapper pdt-50 pdb-50">
                <h1 className="title_h2">
                    {isManual ? t`Zadajte prosím všetky údaje` : t`Skontrolujte prosím správnosť údajov`}
                </h1>

                <div className="box_result mgt-35">
                    <IconLoading show={loadingUpload}/>
                    <div className="box_result_left">
                        <div>
                            <h2 className="text_s12 mg-0 text_w400">{translateText(selectedCard?.labels, lingui.i18n.locale)}:</h2>

                            {
                                selectedCard.sides.map((s,i) => {
                                    const sideEl = cardSides.find(cf => cf.sideType === s.type)

                                    return isManual ? (
                                        <DropzoneUpload
                                            title={translateText(s.labels.title, lingui.i18n.locale)}
                                            key={i}
                                            file={uploadFiles.find(uf => uf.sideType === s.type)?.selectedFile}
                                            setFile={(file => {
                                                setUploadFiles([
                                                    ...uploadFiles.filter(uf => uf.sideType !== s.type),
                                                    {
                                                        sideType: s.type,
                                                        selectedFile: file
                                                    }
                                                ])
                                            })}
                                            error={sideEl?.filename === null}
                                            success={false}
                                        />
                                    ):(
                                        <React.Fragment key={i}>
                                            {
                                                sideEl?.filename ?
                                                <img src={filesURL + '/' + sideEl?.filename} alt="" crossOrigin="anonymous"/>
                                                : null
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>    
                        
                        <div>
                            <h2 className="text_s12 mg-0 text_w400">
                                <Trans>Fotografia tváre</Trans>
                            </h2>

                            {
                                isManual ? (
                                    <DropzoneUpload
                                        title={t`Nahrať fotografiu`}
                                        file={uploadFace}
                                        setFile={(file => {
                                            setUploadFace(file)
                                        })}
                                        error={uploadFace === null}
                                        success={false}
                                    />
                                ):(
                                    <React.Fragment>
                                        {
                                            faceFilename ?
                                            <img src={filesURL + '/' + faceFilename} alt="" crossOrigin="anonymous"/>
                                            : null
                                        }
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </div>

                    <div className="box_result_right"> 
                        <form action="/" method="post">
                            <div className="grid">
                                {readedData.map(s => {
                                    let isDisabled = false

                                    let readedConfidence = 0
                                    
                                    if(s.value && s.value.value.length) {
                                        readedConfidence = s.value.confidence
                                    }

                                    if(readedConfidence >= CONFIDENCE_VALUE) {
                                        // isDisabled = true //TODO: disabled for now, since sometimes inputs are switched
                                    }

                                    return (
                                        <div className={`${(s.ui.size === 'full' || s.ui.size === 'textarea')?'col1':'col2 col1_lgm'} mgb-20`} key={s.key}>
                                            <div className="input_text_cont">
                                                {s.ui.size === 'textarea'?(
                                                    <textarea 
                                                        className={`input_text ${formState.errors[s.key] ? 'invalid' : ''}`}
                                                        required 
                                                        placeholder={translateText(s.ui.title, lingui.i18n.locale)} 
                                                        disabled={isDisabled}
                                                        {
                                                            ...register(s.key, {
                                                                required: {
                                                                    value: true,
                                                                    message: t`Vyplňte` + ' ' + translateText(s.ui.title, lingui.i18n.locale),
                                                                }
                                                            })
                                                        }
                                                    />
                                                ):(
                                                    <input
                                                        className={`input_text ${formState.errors[s.key] ? 'invalid' : ''}`}
                                                        required 
                                                        placeholder={translateText(s.ui.title, lingui.i18n.locale)} 
                                                        type="text"
                                                        disabled={isDisabled}
                                                        {
                                                            ...register(s.key, {
                                                                required: {
                                                                    value: true,
                                                                    message: t`Vyplňte` + ' ' + translateText(s.ui.title, lingui.i18n.locale),
                                                                }
                                                            })
                                                        }
                                                    />
                                                )}
                                                <label className="input_text_placeholder">{translateText(s.ui.title, lingui.i18n.locale)} <small>{(readedConfidence*100).toFixed(2)}%</small></label>
                                            </div>
                                        </div>
                                    )
                                })}

                                <div className="col1">
                                    <label className="input_checkbox_cont">
                                        <input 
                                            name="confirm-box"
                                            id="confirm-box"
                                            type="checkbox"
                                            required
                                            checked={confirmed}
                                            onChange={e => setConfirmed(e.target.checked)}
                                            className="input_checkbox" 
                                        />
                                        <span className="input_checkbox_text">
                                            <Trans>Potvrdzujem správnosť zadaných údajov</Trans>
                                        </span>
                                    </label>    
                                </div>
                                <div className="col1">
                                    <div className={"errors"}>
                                        {
                                            Object.keys(formState.errors).map(key => {
                                                let error = formState.errors[key]
                                                if (error && typeof error.message === 'string') {
                                                    return (
                                                        <div className="box_video_semi box_video_text error" key={key}>
                                                            <div className="icon type4">
                                                                <svg viewBox="0 0 21 21" className="sicofill" xmlns="http://www.w3.org/2000/svg">
                                                                    <use href="/img/layout/icons.svg#s_cross" />
                                                                </svg>
                                                            </div>
                                                            <span>
                                                                {error.message}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                                return null
                                            })
                                        }
                                        {
                                            !confirmed ? 
                                            <div className="box_video_semi box_video_text error">
                                                <div className="icon type4">
                                                    <svg viewBox="0 0 21 21" className="sicofill" xmlns="http://www.w3.org/2000/svg">
                                                        <use href="/img/layout/icons.svg#s_cross" />
                                                    </svg>
                                                </div>
                                                <span>
                                                    <Trans>Podtvrďte správnosť údajov</Trans>
                                                </span>
                                            </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="main_bottom">
                <div className="box_buttons">
                    <div className="box_buttons_left">
                        <ButtonBack 
                            onClick={() => {
                                if(isManual) {
                                    dispatch(setStateStore(EStateActions.GO_HOME, null))
                                } else {
                                    dispatch(setStateStore(EStateActions.GO_PREV, null))
                                }
                            }}
                        />
                    </div>

                    <div className="box_buttons_right">
                        <ButtonSubmitData
                            handleSubmit={handleSubmit}
                            isManual={isManual}
                            confirmed={confirmed}
                            uploadFace={uploadFace}
                            uploadFiles={uploadFiles}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
