import { Reducer } from 'redux'
import { ECameraError } from '../types';
import { EStateActions } from './state';

export interface IDeviceInfo extends MediaDeviceInfo {
    back?: boolean
}

export interface ISystemStore {
    devices: IDeviceInfo[],
    cameraError: ECameraError | null,
    videoIsPlaying: boolean,
    videoOptions: MediaTrackConstraintSet,
}

export enum ESystemActions {
    RESET_STATE = 'RESET_STATE',
    SET_DEVICES = 'SET_DEVICES',
    SET_VIDEO_IS_PLAYING = 'SET_VIDEO_IS_PLAYING',
    SET_VIDEO_OPTIONS = 'SET_VIDEO_OPTIONS',
    SET_CAMERA_ERROR = 'SET_CAMERA_ERROR',
}

const defaultVideoOptions: MediaTrackConstraintSet = {
    width: {min: 640, ideal: 1024},
    height: {min: 360, ideal: 576},
    facingMode: 'environment',

    // test the over-constrained camera error
    // width: {min: 4096},
    // height: {min: 2048},
}

const initialState: ISystemStore = {
    devices: [],
    cameraError: null,
    videoIsPlaying: false,
    videoOptions: defaultVideoOptions,
}

export function setSystemStore(type: ESystemActions, value: any) {
    return { type: type, payload: value}
}

const reducer: Reducer<ISystemStore> = (state = initialState, action) => {
    switch (action.type) {
        case ESystemActions.RESET_STATE:
            return initialState
        case ESystemActions.SET_DEVICES:
            return {
                ...state,
                devices: action.payload
            }
        case ESystemActions.SET_CAMERA_ERROR:
            return {
                ...state,
                cameraError: action.payload
            }
        case EStateActions.GO_MANUAL:
            return {
                ...state,
                cameraError: null
            }
        case ESystemActions.SET_VIDEO_IS_PLAYING:
            return {
                ...state,
                videoIsPlaying: action.payload
            }
        case ESystemActions.SET_VIDEO_OPTIONS:
            return {
                ...state,
                videoOptions: action.payload
            }
    }

    return state
}

export { reducer as systemReducer }
