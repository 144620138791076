import React, { useEffect, useRef } from "react"

import { ScreenForm } from './js/components/screens/ScreenForm';
import { EScreen, ErrorType } from './types';
import { useAppDispatch, useAppSelector } from "./store";
import ScreenLiveness from './js/components/screens/ScreenLiveness';
import ScreenCardDetect from "./js/components/screens/ScreenCardDetect";
import { ScreenCameraError } from './js/components/screens/ScreenCameraError';
import { EStateActions, IPath, setStateStore } from "./reducers/state";
import { ScreenInfoInit } from "./js/components/screens/ScreenInfoInit";
import { AnimatePresence } from "framer-motion";
import Animation from "./js/components/global/Animation";
import { ScreenInvalidVerification } from "./js/components/screens/ScreenInvalidVerification";
import { ScreenSelectCard } from "./js/components/screens/ScreenSelectCard";

interface IProps {
    debug: boolean,
    submitErrorMessage: (error: ErrorType) => void
}

export const MainRouter:React.FC<IProps> = ({
    debug,
    submitErrorMessage
}) => {
    const dispatch = useAppDispatch()
    
    const videoRef = useRef<HTMLVideoElement>(null)
    
    const currentVerification = useAppSelector(store => store.stateStore.currentVerification)
    const cameraError = useAppSelector(store => store.systemStore.cameraError)
    const selectedCard = useAppSelector(store => store.stateStore.selectedCard)
    const navigation = useAppSelector(store => store.stateStore.navigation)
    const currentNavId = useAppSelector(store => store.stateStore.currentNavId)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [currentNavId])

    useEffect(() => {
        let newNavigation: IPath[] = []
        let id = -2

        newNavigation.push({
            id: id++,
            screen: EScreen.cameraError
        })
        
        newNavigation.push({
            id: id++,
            screen: EScreen.manual
        })

        if(currentVerification) {

            newNavigation.push({
                id: id++,
                screen: EScreen.start
            })
    
            newNavigation.push({
                id: id++,
                screen: EScreen.cardSelect
            })

            let useLiveness = false

            if(selectedCard) {
                selectedCard.sides.forEach(s => {
                    newNavigation.push({
                        id: id++,
                        screen: EScreen.cardDetect,
                        sideType: s.type
                    })

                    useLiveness = s.serverSettings.hasFace?true:useLiveness
                })

                if(useLiveness && selectedCard.liveness.allow) {
                    newNavigation.push({
                        id: id++,
                        screen: EScreen.liveness
                    })
                }

                newNavigation.push({
                    id: id++,
                    screen: EScreen.confirm
                })
            }
        } else {
            newNavigation.push({
                id: id++,
                screen: EScreen.missingVerification
            })
        }

        dispatch(setStateStore(EStateActions.SET_NAVIGATION, newNavigation))
    }, [currentVerification, dispatch, selectedCard])

    let finalPath: IPath = {
        id: 0,
        screen: EScreen.start
    }

    let foundCurrentPath = navigation.find(n => n.id === currentNavId)
    let finalCameraError = navigation.find(n => n.screen === EScreen.cameraError)

    if(finalCameraError && cameraError) {
        finalPath = finalCameraError
    } else if (foundCurrentPath) {
        finalPath = foundCurrentPath
    }

    let finalObject: React.ReactNode = null

    switch(finalPath.screen) {
        case EScreen.missingVerification:
            finalObject = (
                <ScreenInvalidVerification/>
            )
            break
        case EScreen.cardSelect:
            finalObject = (
                <ScreenSelectCard/>
            )
            break
        case EScreen.cameraError:
            finalObject = (
                <ScreenCameraError/>
            )
            break
        case EScreen.start:
            finalObject = (
                <ScreenInfoInit/>
            )
            break
        case EScreen.cardDetect:
            if(finalPath.sideType) {
                finalObject = (
                    <ScreenCardDetect
                        debug={debug}
                        key={finalPath.sideType}
                        sendErrorMessage={submitErrorMessage}
                        videoRef={videoRef}
                        sideType={finalPath.sideType}
                    />
                )
            } else {
                finalObject = null
            }
            break
        case EScreen.liveness:
            finalObject = (
                <ScreenLiveness 
                    debug={debug}
                    sendErrorMessage={submitErrorMessage}
                    videoRef={videoRef}
                />
            )
            break
        case EScreen.manual:
            finalObject = (
                <ScreenForm isManual={true}/>
            )
            break
        case EScreen.confirm:
            finalObject = (
                <ScreenForm isManual={false}/>
            )
            break
    }

    return (
        <AnimatePresence mode="wait">
            <Animation type="fade" key={currentNavId} duration={.5} className="main" objType="main">
                {finalObject}
            </Animation>
        </AnimatePresence>
    )
}
