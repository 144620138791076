import React, { useMemo } from 'react'
import { useAppSelector } from '../../../store';
import { EScreen } from '../../../types';
import { Trans } from '@lingui/macro';

interface IProps {}

const MainNavigation: React.FC<IProps> = (props) => {
    const navigation = useAppSelector(store => store.stateStore.navigation)
    const currentNavId = useAppSelector(store => store.stateStore.currentNavId)

    const currentScreen = useMemo(() => navigation.find(n => n.id === currentNavId), [navigation, currentNavId])
    const steps = useMemo(() => {
        let liveness = false

        if(navigation.find(n => n.screen === EScreen.liveness)) {
            liveness = true
        }

        let nav = false
        if(
            currentScreen && 
            [
                EScreen.cardDetect,
                EScreen.confirm,
                EScreen.liveness
            ].includes(currentScreen.screen)
        ) {
            nav = true
        }

        const navSteps: {
            title: React.ReactNode,
            active: boolean
        }[] = []
    
        if(nav && currentScreen) {
            navSteps.push({
                title: (<Trans>Naskenujte svoj doklad totožnosti</Trans>),
                active: [EScreen.cardDetect].includes(currentScreen.screen)
            })

            if(liveness) {
                navSteps.push({
                    title: (<Trans>Biometrické overenie preukazu a identity</Trans>),
                    active: [EScreen.liveness].includes(currentScreen.screen)
                })
            }

            navSteps.push({
                title: (<Trans>Overenie údajov</Trans>),
                active: [EScreen.confirm].includes(currentScreen.screen)
            })
        }

        return navSteps
    }, [currentScreen, navigation])
    
    return steps.length?(
        <div className="box_steps">
            {steps.map((s,i) => (
                <div className={`box_steps_item ${s.active?'active':''}`} key={i}>
                    <div className="box_steps_num">{i+1}<span>/{steps.length}</span></div>
                    <div className="box_steps_text">
                        {s.title}
                    </div>
                </div>
            ))}
        </div>
    ):null
}

export default MainNavigation
