import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../../store/index';

interface IProps {
    show: boolean
}


const DebugImage: React.FC<IProps> = ({show}) => {
    const message = useAppSelector(store => store.wsStore.message)
    
    const [img, setImg] = useState('')
    const [content, setContent] = useState<any>(null)

    useEffect(() => {
        if(show) {
            if(message?.type === 'analyze' && message.data.image) {
                setImg(message.data.image)
                setContent(message.data)
            }

            if(message?.type === 'face' && message.data.face) {
                setContent(message.data)
            }
        }
    }, [message, show])

    if(!show || !content) {
        return null
    }

    let toShow = content
    delete toShow.image
    delete toShow.descriptor

    //console.log(toShow)

    return (
        <>
            <div 
                className="image-holder"
                style={{
                    position: 'absolute',
                    top: "0",
                    left: "0",
                    transform: 'rotateY(180deg) scale(1)', 
                    zIndex: 100,
                    width: "100%",
                    height: "100%",
                    opacity: 0.1,
                    pointerEvents: 'none',
                    // display: "none"
                }}
                >
                <img 
                    alt=""
                    style={{
                        width: "100%",
                        height: "100%"
                    }}
                    src={'data:image/png;base64,' + img}
                />
            </div>
            {typeof content.faceBox !== 'undefined'?(
                <div style={{
                    left: content.faceBox.left + 'px',
                    top: content.faceBox.top + 'px',
                    width: (content.faceBox.right - content.faceBox.left) + 'px',
                    height: (content.faceBox.bottom - content.faceBox.top) + 'px',
                    position: 'absolute',
                    borderWidth: '2px',
                    borderColor: 'red',
                    borderStyle: 'solid',
                    zIndex: 10000
                }}/>
            ):null}
            <pre
                style={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    width: '300px',
                    height: '100vh',
                    lineHeight: '15px',
                    background: '#ffffffaa',
                    top: '100px',
                    margin: 0,
                    display: "none"
                }}
            >
                {JSON.stringify(toShow, null, 2)}
            </pre>
        </>
    )
}

export default DebugImage
