import { useEffect } from "react"
import './js/App.scss';

import config from "./config";
import { EWSActions, setWsStore } from "./reducers/ws";
import { connect } from '@giantmachines/redux-websocket';
import { useAppDispatch, useAppSelector } from "./store";
import { MainRouter } from "./MainRouter";
import { EResultType, IVerificationResult, ErrorType } from './types';
import { IconLoading } from './js/components/icons/IconLoading';
import LayoutContainer from "./js/components/global/LayoutContainer";
import { getVerificationByToken } from "./reducers/api/getVerificationByToken";
import { useLingui } from "@lingui/react";
import { EStateActions, setStateStore } from "./reducers/state";

const DEBUG = window.env.DEBUG_VAL || false

export const Main:React.FC = () => {
    const dispatch = useAppDispatch()
    const { i18n } = useLingui()

    const loading = useAppSelector(store => store.loadingStore.loadingVerification)
    const verification = useAppSelector(store => store.stateStore.currentVerification)
    
    useEffect(() => {
        if(verification === null) {
            const token = new URLSearchParams(window.location.search).get('verification')

            dispatch(setStateStore(EStateActions.SET_VERIFICATION_TOKEN, token))
            getVerificationByToken(token?token:undefined)
        }
    }, [dispatch, verification])

    useEffect(() => {
        if(verification) {
            i18n.activate(verification.language)
        }
    }, [i18n, verification])

    useEffect(() => {
        dispatch(connect(config.ws))

        return () => {
            dispatch(setWsStore(EWSActions.CLEAN_WS, null))
        }
    }, [dispatch])

    const submitErrorMessage = (error: ErrorType) => {
        let result: IVerificationResult = {
            result: EResultType.ERROR,
            errorType: error,
            message: '',
            data: null,
        }
    
        switch (error) {
            case ErrorType.MISSING_CAMERA:
                result.message = 'missing_camera'
                break
            case ErrorType.CANCELED:
                result.message = 'user_cancelled'
                break
            case ErrorType.PERMISSION_DENIED:
                result.message = 'permission_denied'
                break
            case ErrorType.CAMERA_ERROR:
                result.message = 'camera_error_detection'
                break
        }
    
        let message = JSON.stringify(result)
        window.parent.postMessage(message, '*');
    }

    return (
        <LayoutContainer>
            <style>
                {`
                    :root {
                        ${verification && verification.configurations.visual.visualSettings.primaryColor?
                            `
                            --color2: ${verification.configurations.visual.visualSettings.primaryColor};
                            --c4: ${verification.configurations.visual.visualSettings.secondaryColor};
                            --c3: ${verification.configurations.visual.visualSettings.backgroundColor};
                            `:
                            ``
                        }
                    }
                `}
            </style>
            <IconLoading show={loading}/>

            {verification !== null?(
                <MainRouter debug={DEBUG} submitErrorMessage={submitErrorMessage}/>
            ):null}
        </LayoutContainer>
    )
}
