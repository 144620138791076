import React from "react";
import { t } from '@lingui/macro'

interface IProps {
    onClick: () => void,
    customTitle?: string
}

let ButtonBack: React.FC<IProps> = ({onClick, customTitle}) => {
    return (
        <button 
            title="Späť" 
            className="btn_basic more"
            onClick={onClick}
        >
            <span className="arrow">
                <svg className="sicofill" viewBox="0 0 21 11" xmlns="http://www.w3.org/2000/svg">
                    <use href="/img/layout/icons.svg#s_ar_l" />
                </svg>   
            </span>
            <span className="text">{customTitle?customTitle:t`Späť`}</span>
        </button>
    )
}

export default ButtonBack
