import { useAppSelector } from "../../../store";
import { adjustBrightness } from "../../helpers/utils";

export const IconLoading:React.FC<{show: boolean, className?: string}> = ({show, className}) => {
    const verification = useAppSelector(store => store.stateStore.currentVerification)

    if (!show) {
        return null;
    }

    let colorPrimary = '#5D24E5'
    let colorSecondary = '#b5ccf1'

    if(verification && verification.configurations.visual.visualSettings) {
        if(verification.configurations.visual.visualSettings.primaryColor) {
            colorPrimary = verification.configurations.visual.visualSettings.primaryColor
        }
        
        if(verification.configurations.visual.visualSettings.secondaryColor) {
            colorSecondary = verification.configurations.visual.visualSettings.secondaryColor
        }
    }

    let colorPrimaryDark = adjustBrightness(colorPrimary, -30)
    let colorSecondaryLight = adjustBrightness(colorSecondary, -20)

    return (
        <div className="loader">
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                style={{
                    margin: 'auto',
                    background: 'transparent',
                    display: 'block'
                }}
                width="221px" 
                height="221px" 
                viewBox="0 0 100 100" 
                preserveAspectRatio="xMidYMid"
            >
                <circle cx="76" cy="50" r="7" fill={`${colorPrimary}`}>
                <animate attributeName="cx" values="76;50" keyTimes="0;1" dur="0.9111111111111112s" repeatCount="indefinite"></animate>
                <animate attributeName="cy" values="50;76" keyTimes="0;1" dur="0.9111111111111112s" repeatCount="indefinite"></animate>
                <animate attributeName="fill" values={`${colorPrimary};${colorPrimaryDark}`} keyTimes="0;1" dur="0.9111111111111112s" repeatCount="indefinite"></animate>
                </circle><circle cx="50" cy="76" r="7" fill={`${colorPrimaryDark}`}>
                <animate attributeName="cx" values="50;24" keyTimes="0;1" dur="0.9111111111111112s" repeatCount="indefinite"></animate>
                <animate attributeName="cy" values="76;50" keyTimes="0;1" dur="0.9111111111111112s" repeatCount="indefinite"></animate>
                <animate attributeName="fill" values={`${colorPrimaryDark};${colorSecondaryLight}`} keyTimes="0;1" dur="0.9111111111111112s" repeatCount="indefinite"></animate>
                </circle><circle cx="24" cy="50" r="7" fill={`${colorSecondaryLight}`}>
                <animate attributeName="cx" values="24;49.99999999999999" keyTimes="0;1" dur="0.9111111111111112s" repeatCount="indefinite"></animate>
                <animate attributeName="cy" values="50;24" keyTimes="0;1" dur="0.9111111111111112s" repeatCount="indefinite"></animate>
                <animate attributeName="fill" values={`${colorSecondaryLight};${colorSecondary}`} keyTimes="0;1" dur="0.9111111111111112s" repeatCount="indefinite"></animate>
                </circle><circle cx="49.99999999999999" cy="24" r="7" fill={`${colorSecondary}`}>
                <animate attributeName="cx" values="49.99999999999999;76" keyTimes="0;1" dur="0.9111111111111112s" repeatCount="indefinite"></animate>
                <animate attributeName="cy" values="24;49.99999999999999" keyTimes="0;1" dur="0.9111111111111112s" repeatCount="indefinite"></animate>
                <animate attributeName="fill" values={`${colorSecondary};${colorPrimary}`} keyTimes="0;1" dur="0.9111111111111112s" repeatCount="indefinite"></animate>
                </circle>
            </svg>
        </div>
    )
}