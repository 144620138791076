import React from 'react'
import { useLingui } from '@lingui/react';
import { useAppSelector } from '../../../store';

interface IProps {}

const LanguageSwitch: React.FC<IProps> = (props) => {
    const verification = useAppSelector(state => state.stateStore.currentVerification)

    const lingui = useLingui()

    const allowedLanguages = verification?.configurations.visual.visualSettings.languages

    if(!allowedLanguages || allowedLanguages.length === 1) {
        return null
    }

    return (
        <ul className="languages">
            {allowedLanguages.map((lang, index) => {
                return (
                    <li 
                        key={index}
                        className={lingui.i18n.locale === lang?'active':''}
                        onClick={() => {
                            lingui.i18n.activate(lang)
                        }}
                    >
                        {lang.toUpperCase()}
                    </li>
                )
            })}
        </ul>
    )
}

export default LanguageSwitch
