import {useRef, useEffect} from 'react';
import { logDebug } from '../helpers/logger';

const useCleanup = (val: MediaStream|null) => {
    const valRef = useRef(val);

    useEffect(() => {
       valRef.current = val;
    }, [val])

    useEffect(() => {
        return () => {
            // cleanup based on valRef.current
            let stream = valRef.current
            if (stream) {
                logDebug('calling cleanup function for video stream: ', stream.id)

                stream.getTracks().forEach(track => {
                    logDebug('stopping media stream track id ' + track.id)
                    logDebug('stopping media stream track device id: ' + track.getSettings().deviceId)
                    track.stop();
                });
            }
        }
    }, [])
}

export default useCleanup