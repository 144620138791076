import React from 'react'
import { INotificationFull } from '../../../types';
import { deleteFloatingNotification, updateNotifications } from '../../../reducers/notifications';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import useInterval from '../../hooks/useInterval';
import Notification from '../functional/Notification';

interface IProps {

}

const FloatingNotificationsContainer: React.FC<IProps> = () => {
    const dispatch = useAppDispatch()

    const floatingNotifications = useAppSelector(state => state.notificationsStore.floatingNotifications)

    useInterval(() => {
        // Dispatch only if there are some notifications
        if (floatingNotifications.length) {
            dispatch(updateNotifications(floatingNotifications))
        }
    }, 250)

    return (
        <div id="floating-notifications">
            {floatingNotifications.map((notif: INotificationFull, index:number) => {    
                return (
                    <Notification
                        data={notif} 
                        key={index}
                        deleteFunction={() => dispatch(deleteFloatingNotification(notif.id))}
                    />
                )
            })}
            
        </div>
    )
}

export default FloatingNotificationsContainer
