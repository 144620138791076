import { useEffect, useState } from "react"
import { logInfo } from "../helpers/logger"
var mobile = require('is-mobile')

export const useIsMobileDevice = (): boolean => {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        //let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
        let value = mobile({tablet: true})
        logInfo('is mobile device: ' + (value ? 'true' : 'false'))

        setIsMobile(value)
    }, [])

    return isMobile
}