import React, { useEffect, useState } from 'react';
import { addFloatingNotification } from '../../reducers/notifications';
import useCleanup from './useCleanup';
import { initialiseCamera, getAvailableDevices, checkCameraSupported } from '../helpers/utils';

import { logInfo, logDebug, logError } from '../helpers/logger';
import { ECameraError, ErrorType } from '../../types';
import { useAppSelector, useAppDispatch } from '../../store/index';
import { ESystemActions, setSystemStore, IDeviceInfo } from '../../reducers/system';
import { setStateStore, EStateActions } from '../../reducers/state';
import { t } from '@lingui/macro';

const cameraOccupiedErros = [
    'Could not start video source', // chrome
    'Failed to allocate videosource', // mozilla
]

export const useCamera = (
    videoRef: React.RefObject<HTMLVideoElement>, 
    options: MediaTrackConstraintSet,
    sendErrorMessage: (error: ErrorType) => void
): boolean => 
{
    const dispatch = useAppDispatch()

    const deviceId = useAppSelector(store => store.stateStore.deviceId)
    const devices = useAppSelector(store => store.systemStore.devices)

    const [currentStream, setCurrentStream] = useState<MediaStream|null>(null)
    const [currentDevice, setCurrentDevice] = useState<string>('')

    const [initialising, setInitialising] = useState(false)

    useCleanup(currentStream)

    useEffect(() => {
        if (initialising || (deviceId !== '' && deviceId === currentDevice)) {
            return
        }
        setInitialising(true)
        checkCameraSupported(sendErrorMessage)

        if (currentStream) {
            currentStream.getTracks().forEach(track => {
                logDebug('stopping media stream track id = ' + track.id)
                track.stop();
            });
        }

        initialiseCamera(options, deviceId)
            .then(stream => {
                logDebug('setting videostream for deviceID: ' + deviceId)
                setCurrentStream(stream)

                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }

                let tracks = stream.getTracks()
                if (tracks.length > 0) {
                    let track = tracks[0]
                    let settings = track.getSettings()
                    
                    logDebug('selected video stream', stream)
                    logDebug('selected track', track)
                    logDebug('Track constraints', track.getConstraints())
                    logDebug('Track settings', settings)

                    let selectedDeviceID = settings.deviceId ?? ''
                    setCurrentDevice(selectedDeviceID)

                    if (!deviceId && selectedDeviceID) {
                        logDebug('setting device ID into redux store: ', selectedDeviceID)
                        dispatch(setStateStore(EStateActions.SET_DEVICE_ID, selectedDeviceID))

                        if (devices.length === 0) {
                            getAvailableDevices().then(devices => {
                                let newDevices: IDeviceInfo[] = devices.map( d => {
                                    return {
                                        ...d.toJSON(),
                                        back: (d.deviceId === selectedDeviceID || d.label.indexOf('back') >= 0)
                                    }
                                })

                                console.log(newDevices)
                                logInfo('available video devices: ', newDevices)
                                dispatch(setSystemStore(ESystemActions.SET_DEVICES, newDevices))

                                if (newDevices.length === 0) {
                                    sendErrorMessage(ErrorType.MISSING_CAMERA)
                                    dispatch(addFloatingNotification({text: t`Nepodarilo sa detegovať žiadnu kameru`, type: 'error', showBell: true}))
                                    logError('There are no available camera devices')
                                }
                            })
                        }
                    }
                }
                setInitialising(false)
            })
            .catch( (e: DOMException) => {
                logError('camera error: ' + e.name)
                logError('error message: ' + e.message)

                sendErrorMessage(ErrorType.CAMERA_ERROR)
                logError('Could not access camera')
                dispatch(addFloatingNotification({text: t`Nepodarilo sa získať prístup ku kamere`, type: 'error', showBell: true}))

                console.log(e)
                if (e.name === 'NotAllowedError' || e.name === 'PermissionDismissedError') {
                    sendErrorMessage(ErrorType.PERMISSION_DENIED)
                    logError('Permission denied')
                    dispatch(setSystemStore(ESystemActions.SET_CAMERA_ERROR, ECameraError.PermissionDenied))
                } else {
                    if (navigator.userAgent.includes('Windows') && cameraOccupiedErros.some(err => e.message.includes(err))) {
                        dispatch(setSystemStore(ESystemActions.SET_CAMERA_ERROR, ECameraError.CameraOccupied))
                    } else {
                        if (e.name === 'OverconstrainedError') {
                            dispatch(setSystemStore(ESystemActions.SET_CAMERA_ERROR, ECameraError.Overconstrained))
                        } else {
                            dispatch(setSystemStore(ESystemActions.SET_CAMERA_ERROR, ECameraError.UnknownError))
                        }
                    }
                }

                setInitialising(false)
                setCurrentDevice('error')
                dispatch(setStateStore(EStateActions.SET_DEVICE_ID, 'error'))
            });
    }, [dispatch, sendErrorMessage, videoRef, deviceId, options, currentDevice, currentStream, initialising, devices.length]);

    return initialising
};
