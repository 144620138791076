import config, { ECardBackendType, ECardType } from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';
import { addFloatingNotification } from '../notifications';
import { setLoadingStore, ELoadingActions } from '../loading';
import { logError } from '../../js/helpers/logger';
import { ECardSideType, IOCRResponseText } from '../../types';
import { t } from '@lingui/macro';

interface IOCRResponse {
    message: string,
    image: string, //base64 encoded string
    texts: IOCRResponseText[]
}

const getInfo = async (
    image: string, 
    side: ECardSideType, 
    cardType: ECardBackendType,
    expectedTexts: string[]
): Promise<IOCRResponse|null> => {
    store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_OCR_BACK, true))

    let headers = {
        'Content-Type': 'application/json'
    }

    return fetch(config.api.url + '/get-info', {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            image: image,
            sideType: side,
            backendType: cardType,
            expectedTexts: expectedTexts
        }),
    })
        .then(handleErrors)
        .then(res => res.json())
        .then((res) => {
            store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_OCR_BACK, false))

            return res.data
        })
        .catch((error: any)  => {
            store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_OCR_BACK, false))

            store.dispatch(addFloatingNotification({
                text: t`Nepodarilo sa extrahovať údaje`,
                type: 'error',
                showBell: true
            }))

            logError('Could not extract back side info', error)
            return null
        });
}

export default getInfo
