import { useState, useEffect, useMemo } from "react";
import { EFaceResponse, EImageAnalyse, ELivenessResponse, IResponseAnalyzeData, IImageAnalyseResult, IWSMessage } from '../../types';
import config from "../../config";
import { EIconType } from "../components/functional/LiveVideoElement";
import { t } from '@lingui/macro';

export interface ISize {
    width: number;
    height: number;
}

type ResultsKey = keyof IImageAnalyseResult

export interface IResponse {
    showMessage: string,
    frameClass: string,
    iconType: EIconType
}

export function useMessage(message?: IWSMessage, wantValidFace?: boolean, isValidImages?: boolean): IResponse {
    const [messages, setMessages] = useState<IResponseAnalyzeData[]>([])

    useEffect(() => {
        if (message && message.type === 'analyze') {
            setMessages(oldMessages => {
                if (oldMessages.length > config.thresholds.messages_history_length) {
                    oldMessages.shift()
                }

                return [
                    ...oldMessages,
                    message.data
                ]
            })
        }
    }, [message])

    let analyzeMessage = useMemo(() => {
        let keys = ['correct', 'grayscale', 'empty', 'to_left', 'to_right', 'to_bottom', 'to_top', 'low_b', 'low_s', 'small', 'big']

        let counts = {
            correct: 0,
            empty: 0,
            to_left: 0,
            to_right: 0,
            to_bottom: 0,
            to_top: 0,
            low_b: 0,
            low_s: 0,
            small: 0,
            big: 0,
            grayscale: 0,
        }

        messages.forEach((m) => {
            keys.forEach((k: string) => {
                let key = k as ResultsKey
                
                if (m.results[key]) {
                    counts[key] += 1
                }
            })
        })

        let maxKey = 'correct'
        let maxCount = 0;

        keys.forEach((k: string) => {
            let key = k as ResultsKey

            if (counts[key] > maxCount) {
                maxKey = key
                maxCount = counts[key]
            }
        })

        if (!(messages.length)) {
            maxKey = 'empty'
        }

        let newMessage = ''
        let newFrameClass = 'error'
        let newIconType = EIconType.error

        if (maxKey === EImageAnalyse.correct) {
            newFrameClass = 'ok'
            newIconType = EIconType.success
        } else if (['small', 'big'].includes(maxKey)) {
            newFrameClass = ''
            newIconType = EIconType.success
        }
        
        if(wantValidFace && !isValidImages && maxKey === EImageAnalyse.correct) {
            maxKey = EImageAnalyse.missing_face
            newIconType = EIconType.warning
        }

        switch (maxKey) {
            case EImageAnalyse.correct:
                newMessage = t`Držte preukaz v zobrazenom rámčeku, prebieha overenie!`
                break
            case EImageAnalyse.missing_face:
                newMessage = t`Nepodarilo sa detekovať tvár. Odstráňte odlesky.`
                break
            case EImageAnalyse.to_left:
                newMessage = t`Príliš vpravo.`
                newIconType = EIconType.warning
                break
            case EImageAnalyse.to_right:
                newMessage = t`Príliš vľavo.`
                newIconType = EIconType.warning
                break
            case EImageAnalyse.to_bottom:
                newMessage = t`Príliš vysoko.`
                newIconType = EIconType.warning
                break
            case EImageAnalyse.to_top:
                newMessage = t`Príliš nízko.`
                newIconType = EIconType.warning
                break
            case EImageAnalyse.small:
                newMessage = t`Preukaz je príliš malý.`
                newIconType = EIconType.warning
                break
            case EImageAnalyse.big:
                newMessage = t`Preukaz je príliš veľký.`
                newIconType = EIconType.warning
                break
            case EImageAnalyse.low_b:
                newMessage = t`Málo svetla.`
                newIconType = EIconType.warning
                break
            case EImageAnalyse.low_s:
                newMessage = t`Zaostrite prosím.`
                newIconType = EIconType.warning
                break
            case EImageAnalyse.grayscale:
                newMessage = t`Nepodporovaný typ kamery, prosím zvoľte inú kameru`
                newIconType = EIconType.error
                break
            default:
                newMessage = t`Vložte občianský preukaz do zobrazeného rámiku.`
                newIconType = EIconType.error
                break
        }
    
        return {
            showMessage: newMessage, 
            frameClass: newFrameClass,
            iconType: newIconType
        }

    }, [messages, isValidImages, wantValidFace])

    let faceMessage = useMemo(() => {
        let newMessage = ''
        let newFrameClass = 'error'
        let newIconType = EIconType.error

        if (message && message.type === 'face') {
            if (message.data) {

                if (message.data.response === EFaceResponse.correct) {
                    newFrameClass = 'ok'
                    newIconType = EIconType.success
                } else if ([EFaceResponse.out_area].includes(message.data.response)) {
                    newFrameClass = ''
                    newIconType = EIconType.success
                }

                switch(message.data.response) {
                    case EFaceResponse.correct:
                        newMessage = t`Prebieha overenie... Udržujte tvár v zobrazenom rámčeku.`
                        break
                    case EFaceResponse.not_valid:
                        newMessage = t`Podobnosť tváre je príliš nízka.`
                        newIconType = EIconType.error
                        break
                    case EFaceResponse.out_area:
                        newMessage = t`Zamerajte svoju tvár do zobrazeného rámika.`
                        newIconType = EIconType.warning
                        break
                    case EFaceResponse.too_big:
                        newMessage = t`Príliš blízko.`
                        newIconType = EIconType.warning
                        break
                    case EFaceResponse.too_small:
                        newMessage = t`Príliš ďaleko.`
                        newIconType = EIconType.warning
                        break
                    default:
                        newMessage = t`Tvár sa nepodarilo detekovať.`
                        newIconType = EIconType.error
                        break
                }
            }
        }

        if(message && message.type === 'liveness') {
            switch(message.data.response) {
                case ELivenessResponse.not_correct:
                    newMessage = t`Podobnosť tvárí nie je dostatočná.`
                    newIconType = EIconType.error
                    break
                case ELivenessResponse.emotion_invalid:
                    switch(message.data.emotionType) {
                        case 'happy':
                            newMessage = t`Usmejte sa.`
                            newIconType = EIconType.warning
                            break
                        case 'surprised':
                            newMessage = t`Tvárte sa prekvapene.`
                            newIconType = EIconType.warning
                            break
                        default:
                            newMessage = t`Tvárte sa neutrálne.`
                            newIconType = EIconType.warning
                            break
                    }
                    break
                case ELivenessResponse.emotion_valid:
                    newFrameClass = 'ok'

                    switch(message.data.emotionType) {
                        case 'happy':
                            newMessage = t`Usmievajte sa, overujem.`
                            newIconType = EIconType.success
                            break
                        case 'surprised':
                            newMessage = t`Ostaňte prekvapení, overujem.`
                            newIconType = EIconType.success
                            break
                        default:
                            newMessage = t`Tvárte sa neutrálne, overujem.`
                            newIconType = EIconType.success
                            break
                    }
                    break
            }
        }

        return {
            showMessage: newMessage, 
            frameClass: newFrameClass,
            iconType: newIconType
        }

    }, [message])

    let defaultValue = {
        showMessage: '',
        frameClass: 'error',
        iconType: EIconType.error
    }

    if (message) {
        if (message.type === 'analyze') {
            return analyzeMessage
        } else if (message.type === 'face' || message.type === 'liveness') {
            return faceMessage
        }
    }

    return defaultValue
}
