import React, { useEffect } from "react";
import MainNavigation from "../global/MainNavigation";
import { t } from '@lingui/macro';
import { EResultType } from "../../../types";

interface IProps {}

export const ScreenInvalidVerification:React.FC<IProps> = () => {
    
    useEffect(() => {
        window.parent.postMessage(JSON.stringify({
            result: EResultType.ERROR,
            message: 'verification_init_error'
        }), '*')
    }, [])
    
    return (
        <>
            <MainNavigation/>
            <div className="box_video nocamera">
                <div className="box_video_inner">
                    <div className="box_video_content box_error_verification">

                        <svg className="image" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 2H8C4.691 2 2 4.691 2 8v13a1 1 0 0 0 1 1h13c3.309 0 6-2.691 6-6V8c0-3.309-2.691-6-6-6zm4 14c0 2.206-1.794 4-4 4H4V8c0-2.206 1.794-4 4-4h8c2.206 0 4 1.794 4 4v8z"></path><path d="M11 6h2v8h-2zm0 10h2v2h-2z"></path>
                        </svg>
                        
                        <div className="box_video_cover"></div>
                        <style>
                            {`
                                .box_video{
                                    --h-per: 56%;
                                    --w-per: 56%;
                                    --w-m-per: 88%;
                                    --h-m-per: 44%;
                                }
                            `}
                        </style>

                        <div className="box_video_frame">
                        </div>

                        <div className="box_video_bottom">
                            <div className="box_video_semi box_video_text w100p">
                                <span className="icon type2">
                                    <svg viewBox="0 0 21 21" className="sicofill" xmlns="http://www.w3.org/2000/svg">
                                        <use href="/img/layout/icons.svg#s_cross" />
                                    </svg>
                                </span>
                                <span>{t`Verifikáciu sa nepodarilo iniciovať. Obráťte sa na administrátora systému.`}</span>
                            </div>
                        </div>

                    </div>
                </div>            
            </div>
        </>
    )
}
