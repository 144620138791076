const DEBUG = window.env.DEBUG_VAL || false
const MAX_LOGS = 500

declare global {
    interface Window { printLogs: () => void }
}

const prependZero = (v: any):string => {
    return ('0' + v).slice(-2)
}

const getDateString = () => {
    let date = new Date()

    return prependZero(date.getDate())  + "-" + prependZero((date.getMonth()+1)) + "-" + date.getFullYear() + " " + prependZero(date.getHours()) + ":" + prependZero(date.getMinutes()) + ":" + prependZero(date.getSeconds())
}

function setToLocalStorage(dateString: string, message: string, type: string, data: any) {
    let logs = []
    let logsStr = localStorage.getItem('logs')

    if (logsStr) {
        logs = JSON.parse(logsStr)
    }

    if (logs.length > MAX_LOGS) {
        logs.shift()
    }

    logs.push({
        date: dateString,
        message: message,
        type: type,
        data: data
    })

    localStorage.setItem('logs', JSON.stringify(logs))
}

function logFunction(message: string, type: string, data?: any) {
    let dateString = getDateString()

    if (DEBUG) {

        let str = dateString + ' - ' + type + ': ' + message;
        if (data) {
            console.log(str, data)
        } else {
            console.log(str)
        }
    }

    setToLocalStorage(dateString, message, type, data)
}

export function logDebug(message: string, data?: any) {
    logFunction(message, 'DEBUG', data)
}

export function logInfo(message: string, data?: any) {
    logFunction(message, 'INFO', data)
}

export function logWarning(message: string, data?: any) {
    logFunction(message, 'WARN', data)
}

export function logError(message: string, data?: any) {
    logFunction(message, 'ERROR', data)
}

if (typeof(window.printLogs) === 'undefined') {
    let printLogs = () => {
        let logs = []

        let logsStr = localStorage.getItem('logs')
        if (logsStr) {
            logs = JSON.parse(logsStr)
        }
        console.log(logs)
    }

    window.printLogs = printLogs
}
