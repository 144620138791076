import { Reducer } from 'redux'

export interface ILoadingStore {
    loadingLogin: boolean,
    loadingUploadFile: boolean,
    loadginOCRFront: boolean,
    loadingOCRBack: boolean,
    loadingVerification: boolean,
    loadingVerificationJournal: boolean
}

export enum ELoadingActions {
    SET_LOADING_LOGIN = 'SET_LOADING_LOGIN',
    SET_LOADING_UPLOAD_FILE = 'SET_LOADING_UPLOAD_FILE',
    SET_LOADING_OCR_FRONT = 'SET_LOADING_OCR_FRONT',
    SET_LOADING_OCR_BACK = 'SET_LOADING_OCR_BACK',
    SET_LOADING_VERIFICATION = 'SET_LOADING_VERIFICATION',
    SET_LOADING_VERIFICATION_JOURNAL = 'SET_LOADING_VERIFICATION_JOURNAL'
}

export function setLoadingStore(type: ELoadingActions, value: boolean) {
    return { type: type, payload: value }
}

const initialState: ILoadingStore = {
    loadingLogin: false,
    loadingUploadFile: false,
    loadginOCRFront: false,
    loadingOCRBack: false,
    loadingVerification: false,
    loadingVerificationJournal: false
}

const reducer: Reducer<ILoadingStore> = (state = initialState, action) => {
    switch (action.type) {
        case ELoadingActions.SET_LOADING_LOGIN:
            state = {
                ...state,
                loadingLogin: action.payload
            }
            break
        case ELoadingActions.SET_LOADING_UPLOAD_FILE:
            state = {
                ...state,
                loadingUploadFile: action.payload
            }
            break
        case ELoadingActions.SET_LOADING_OCR_FRONT:
            state = {
                ...state,
                loadginOCRFront: action.payload
            }
            break
        case ELoadingActions.SET_LOADING_OCR_BACK:
            state = {
                ...state,
                loadingOCRBack: action.payload
            }
            break
        case ELoadingActions.SET_LOADING_VERIFICATION:
            state = {
                ...state,
                loadingVerification: action.payload
            }
            break
        case ELoadingActions.SET_LOADING_VERIFICATION_JOURNAL:
            state = {
                ...state,
                loadingVerificationJournal: action.payload
            }
            break
    }
    return state
}

export { reducer as loadingReducer }
