import React from 'react';
import { useDropzone } from 'react-dropzone';
import { IFile } from '../../../types';

interface IProps {
    file?: IFile | null,
    setFile: (file: IFile | null) => void,
    title: string,
    error: boolean,
    success: boolean,
}

const DropzoneUpload: React.FC<IProps> = ({
    file,
    setFile,
    title,
    error,
    success
}) => {

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 0) {
                let file = acceptedFiles[0]

                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
                setFile(file)
            }
        },
        accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg', '.jpeg'],
            'image/bmp': ['.bmp'],
            'image/tiff': ['.tif', '.tiff'],
        },
        multiple: false,
        maxFiles: 1
    })

    return (
        <div className={`upload-box${error ? ' _error' : ''}${success ? ' _success' : ''}`}>
            <div className={'inner dropzone alt op'} {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    !file ?
                    <>
                        <div className="upload-icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-upload"
                                width="44"
                                height="44"
                                viewBox="0 0 24 24"
                                strokeWidth="0.8"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >

                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                <polyline points="7 9 12 4 17 9" />
                                <line x1="12" y1="4" x2="12" y2="16" />
                            </svg>
                        </div>
                        <h5 className="h4">{title}</h5>
                    </> 
                    :  
                    <>
                        <button
                            className="photo-remove"
                            onClick={e => {
                                e.stopPropagation()
                                setFile(null)
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-x"
                                viewBox="0 0 24 24"
                                stroke="#b00020"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                        <img
                            alt={'Logo'}
                            src={file.preview}
                        />
                    </>
                }
            </div>
        </div>
    )
}

export default DropzoneUpload
