import { useIsMobileDevice } from "./useIsMobileDevice"
import { useAppSelector } from '../../store/index';

export const useShouldMirrorVideo = (): boolean => {
    const isMobile = useIsMobileDevice()
    const deviceId = useAppSelector(store => store.stateStore.deviceId)
    const devices = useAppSelector(store => store.systemStore.devices)

    let currentDevice = devices.find(d => d.deviceId === deviceId)

    if (
        (isMobile && currentDevice && currentDevice.back) ||
        currentDevice?.label.toLowerCase().includes('iphone')
    ) {
        return false
    }

    return true
}
