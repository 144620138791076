import { store } from '../../store';
import { ECameraError, ErrorType, IFaceDimensions, ITranslatable } from '../../types';
import { logInfo, logError } from './logger';
import { ESystemActions, setSystemStore } from '../../reducers/system';
import config from '../../config';

export const getAvailableDevices = async (): Promise<MediaDeviceInfo[]> => {
    let list: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
    return list.filter(d => d.kind === 'videoinput');
}

export const initialiseCamera = async(options: MediaTrackConstraintSet, deviceId?: string|null): Promise<MediaStream> => {
    let videoConstraints = options;

    if (deviceId && deviceId !== 'error') {
        videoConstraints.deviceId = {
            exact: deviceId
        }
        videoConstraints.facingMode = undefined
    }
    
    let constraints: MediaStreamConstraints = {
        video: videoConstraints,
        audio: false
    }

    logInfo('initializing camera with constraints: ', constraints)
    return navigator.mediaDevices.getUserMedia(constraints);
}

export const checkCameraSupported = (sendErrorMessage: (error: ErrorType) => void) => {
    let check = (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices)

    if (check) {
        logError("Error: enumerateDevices() not supported.");
        
        store.dispatch(setSystemStore(ESystemActions.SET_CAMERA_ERROR, ECameraError.CameraNotSupported))
        sendErrorMessage(ErrorType.MISSING_CAMERA)
    }
}

export interface IResponse {
    reduced: string, fullPhoto: string, box: IFaceDimensions|null
}

export const drawPhoto = (
    videoEl: HTMLVideoElement,
    canvas: HTMLCanvasElement,
    absoluteBox: boolean = false,
): IFaceDimensions|null => {
    let videoAspectRatio = videoEl.videoWidth / videoEl.videoHeight
    let containerAspectRatio = videoEl.clientWidth / videoEl.clientHeight

    let sx, sy, sWidth, sHeight = 0


    if (videoAspectRatio > containerAspectRatio) {
        sHeight = videoEl.videoHeight
        sWidth = containerAspectRatio * sHeight
        sx = (videoEl.videoWidth - sWidth) / 2
        sy = 0
    } else {
        sWidth = videoEl.videoWidth
        sHeight = sWidth / containerAspectRatio
        sx = 0
        sy = (videoEl.videoHeight - sHeight) / 2
    }

    canvas.width = videoEl.clientWidth
    canvas.height = videoEl.clientHeight

    let in_ctx = canvas.getContext('2d')
    if (in_ctx) {
        in_ctx.drawImage(videoEl, sx, sy, sWidth, sHeight, 0, 0, canvas.width, canvas.height)
    } else {
        logError('the canvas is null')
    }

    return getCropBox(videoEl.clientWidth, videoEl.clientHeight, absoluteBox)
}

export const getCropBox = (total_width: number, total_height: number, absolute = false): IFaceDimensions|null => {
    let frameId = document.getElementById('video_overlay_mask')
    let parentFrameId = document.getElementById('video_overlay_mask_parent')

    if(frameId && parentFrameId) {
        let frameRect = frameId.getBoundingClientRect()
        let parentRect = parentFrameId.getBoundingClientRect()

        if (frameRect.height === 0) {
            logInfo('frame has 0 height, returning null')
            return null
        }
        
        let dimensions = {
            top: (frameRect.top - parentRect.top)/parentRect.height,
            left: (frameRect.left - parentRect.left)/parentRect.width,
            width: frameRect.width / parentRect.width,
            height: frameRect.height / parentRect.height,
            totalWidth: total_width,
            totalHeight: total_height
        }

        if (absolute) {
            dimensions.top *= total_height
            dimensions.height *= total_height

            dimensions.left *= total_width
            dimensions.width *= total_width
        }
        return dimensions
    }

    logInfo('cannot find frame element, returning null ...')
    return null
}

export const calculateMaskSize = (
    clientWidth: number,
    clientHeight: number,
    aspectWidth: number|undefined,
    aspectHeight: number|undefined
) => {
    let heightMask = (60/100)*clientHeight
    let widthMask = (aspectHeight && aspectWidth)?((aspectWidth/aspectHeight)*heightMask):0.56*clientWidth
    let widthMask2 = (0.95*clientWidth)
    let heightMask2 = widthMask2/((aspectHeight && aspectWidth)?(aspectWidth/aspectHeight):0.56)

    if(widthMask >= (clientWidth*0.95)) {
        widthMask = widthMask2
        heightMask = heightMask2
    }

    return {
        mask: (
            <style>
                {`
                    .box_video_cover {
                        -webkit-mask-image: url("${config.api.url}/mask/${clientWidth}/${widthMask}/${clientHeight}/${heightMask}/file.svg");
                        mask-image: url("${config.api.url}/mask/${clientWidth}/${widthMask}/${clientHeight}/${heightMask}/file.svg");
                    }
                `}
            </style>
        ),
        styles: (
            <style>
                {`
                    .box_video{
                        --h-per: ${heightMask/clientHeight*100}%;
                        --w-per: ${widthMask/clientWidth*100}%;
                        --w-m-per: ${widthMask/clientWidth*100}%;
                        --h-m-per: ${heightMask/clientHeight*100}%;
                    }
                `}
            </style>
        ),
        overlayMask: (
            <svg id="video_overlay_mask_parent" className="first" viewBox={`0 0 ${clientWidth} ${clientHeight}`} vectorEffect="non-scaling-stroke" xmlns="http://www.w3.org/2000/svg">
                <rect  id="video_overlay_mask" x={((clientWidth-widthMask)/2) + 'px'} y={((clientHeight-heightMask)/2) + 'px'} width={widthMask + 'px'} height={heightMask + 'px'} rx="20" ry="20" className="box_video_stroke" />
            </svg>
        )
    }
}

export const translateText = (text: ITranslatable, locale: string) => {
    switch(locale) {
        case 'en':
            return text.en
        default:
            return text.sk
    }
}

function hexToRgb(hex: string) {
    let bigint = parseInt(hex.slice(1), 16)
    let r = (bigint >> 16) & 255
    let g = (bigint >> 8) & 255
    let b = bigint & 255

    return { r, g, b }
}

export const rgbToHex = (r: number, g: number, b: number): string => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
}

export const adjustBrightness = (hex: string, percent: number): string => {
    let { r, g, b } = hexToRgb(hex)

    r = Math.min(255, Math.max(0, r + Math.round((255 * (percent / 100)))))
    g = Math.min(255, Math.max(0, g + Math.round((255 * (percent / 100)))))
    b = Math.min(255, Math.max(0, b + Math.round((255 * (percent / 100)))))

    return rgbToHex(r, g, b)
}