import { store } from '../../store';
import { addFloatingNotification } from '../../reducers/notifications';
import { logError } from './logger';
import { t } from '@lingui/macro';

export function handleErrors(response: any) {
    if(response.status === 409 || response.status === 204)  {
        return response
    }

    if (!response.ok) {
        if(response.status ===  401 || response.status === 403) {

            throw Error(response.statusText);

        } else if(response.status > 400 && response.status < 500) {
            if(response.status !== 404) {
                store.dispatch(addFloatingNotification({
                    type:"warning",
                    text:t`Nastala chyba v komunikácii so serverom!` + '( ' + response.statusText + ' )',
                    showBell: true
                }))
            } else {
                try {
                    response.json().then((data:any) => {
                        if(response.status !== 404) {
                            store.dispatch(addFloatingNotification({
                                type: "warning",
                                text: JSON.stringify(data)
                            }))
                        }
                    })
                } catch(e) {
                    logError('Could not parse response json', e)
                }
            }
            throw Error(response.statusText);
        }
        
        store.dispatch(addFloatingNotification({
            type:"error",
            text: t`Nastala neočakávaná chyba!`,
            showBell: true
        }))

        logError('Request error: ', response);
        throw Error(response.statusText);
    }
    return response;
}