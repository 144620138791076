import config from "../../config";
import { handleErrors } from "../../js/helpers/handleErrors";
import { store } from "../../store";
import { EVerificationJournalMessage } from "../../types";
import { ELoadingActions, setLoadingStore } from "../loading";

export const addVerificationJournalData = async (
    verificationToken: string|null, 
    action: EVerificationJournalMessage, 
    data: any,
    files: {
        [key: string]: string
    } = {}
): Promise<null> => {
    if(!verificationToken) {
        return null
    }

    store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_VERIFICATION_JOURNAL, true))

    let headers = {
        'Content-Type': 'application/json'
    }

    return fetch(`${config.api.url}/verifications/${verificationToken}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            action: action,
            data: data,
            files: files
        })
    })
        .then(handleErrors)
        .then(res => res.json())
        .then((res) => {
            store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_VERIFICATION_JOURNAL, false))
            return null
        })
        .catch((error: any)  => {
            console.log(error)
            store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_VERIFICATION_JOURNAL, false))
            return null
        })
}