declare global {
  interface Window {
      env: any
  }
}

window.env = window.env || {}

let apiUrl = window.env.REACT_APP_API_URL
let socketUrl = window.env.REACT_APP_WS_URL
let assetUrl = window.env.REACT_APP_ASSET_URL


export enum ECardType {
    slovak_personal_id = 'slovak_personal_id',
    swiss_personal_id = 'swiss_personal_id'
}

export enum ECardBackendType {
    slovak_personal_id = 'slovak_personal_id',
    slovak_personal_id_2023 = 'slovak_personal_id_2023',
    swiss_personal_id = 'swiss_personal_id'
}

let config = {
    api: {
        url: apiUrl,
        filesUrl: apiUrl + '/files',
    },
    ws: socketUrl,
    assets: {
        apiUrl: assetUrl
    },
    thresholds: {
        messages_history_length: 5,
    },
    dimensions: {
        aspect_ratio: 85.0/54.0,
        width_in_pixels: 640, // output photo width in pixels, used in final segmentation task
        ID_frame_width_percent: 65, // ID frame width ralative to vide width
        face_frame_width_percent: 35, // face frame width relative to video width
    },
    delay: { // time delay beteen frames being proccesed in milliseconds
        emotions: 350,
        faceTracking: 350,
        id_photo: 150
    }
}

export default config
