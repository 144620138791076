import React, { useState } from "react";
import { setSystemStore, ESystemActions } from '../../../reducers/system';
import { logInfo } from '../../helpers/logger';
import { useAppSelector, useAppDispatch } from '../../../store/index';
import { setStateStore, EStateActions } from '../../../reducers/state';
import { t } from '@lingui/macro';

interface IProps {}

export const CameraSwitch: React.FC<IProps> = () => {
    const dispatch = useAppDispatch()

    const devices = useAppSelector(store => store.systemStore.devices)
    const deviceId = useAppSelector(store => store.stateStore.deviceId)
    
    const [showCameraMenu, setShowCameraMenu] = useState(false)

    if(devices.length === 1) {
        return null
    }

    return (
        <div className="box_video_drop">
            <button 
                type="button" 
                className={`box_video_camera box_video_semi ${showCameraMenu?'active':''}`}
                onClick={() => {
                    if(devices.length === 2) {
                        let device = devices.find(d => d.deviceId !== deviceId)
                        
                        if(device) {
                            logInfo('selecting device id: ' + device.deviceId)
                            dispatch(setSystemStore(ESystemActions.SET_VIDEO_IS_PLAYING, false))
                            dispatch(setStateStore(EStateActions.SET_DEVICE_ID, device.deviceId))
                        }
                    } else {
                        setShowCameraMenu(!showCameraMenu)
                    }
                }}
            >                           
                <svg className="sico" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                    <use href="/img/layout/icons.svg#s_camera" />
                </svg>
                <span>{devices.length === 2?t`Otočiť kameru`:t`Vybrať kameru`}</span>
            </button>
            {
                showCameraMenu ?
                    <div className="box_video_dropdown">
                        {
                            devices.map((device, index) => {
                                return (
                                    <div
                                        key={device.deviceId}
                                        className={"camera-select" + (device.deviceId === deviceId ? ' active' : '')}
                                        onClick={() => {
                                            logInfo('selecting device id: ' + device.deviceId)
                                            dispatch(setSystemStore(ESystemActions.SET_VIDEO_IS_PLAYING, false))
                                            dispatch(setStateStore(EStateActions.SET_DEVICE_ID, device.deviceId))
                                        }}
                                    >
                                        {device.label ? device.label : t`Kamera` + ' ' + String(index)}
                                    </div>
                                )
                            })
                        }
                    </div>
                : null
            }
        </div>
    )
}
