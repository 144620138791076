import React, { useEffect } from "react";
import { ECameraError, EVerificationJournalMessage } from '../../../types';
import { useAppSelector, useAppDispatch } from '../../../store/index';
import { EStateActions, setStateStore } from '../../../reducers/state';
import { ESystemActions, setSystemStore } from '../../../reducers/system';
import MainNavigation from "../global/MainNavigation";
import { CameraSwitch } from "../functional/CameraSwitch";
import { t, Trans } from '@lingui/macro';
import { addVerificationJournalData } from "../../../reducers/api/addVerificationJournalData";

interface IProps {}

export const ScreenCameraError:React.FC<IProps> = () => {
    const dispatch = useAppDispatch()

    const cameraError = useAppSelector(store => store.systemStore.cameraError)
    const selectedCard = useAppSelector(store => store.stateStore.selectedCard)
    const verificationToken = useAppSelector(store => store.stateStore.verificationToken)

    let message = ''

    switch (cameraError) {
        case ECameraError.CameraNotSupported:
            message = t`Prehliadač nepodporuje prístup ku kamere`
            break
        case ECameraError.CameraOccupied:
            message = t`Kamera je pravdepodobne využívaná inou aplikáciou alebo programom. Zatvorte všetky ostatné aplikácie, ktoré využívajú kameru a skúste znovu.`
            break
        case ECameraError.Overconstrained:
            message = t`Kamera nespĺňa minimálne požiadavky na rozlíšenie videa`
            break
        case ECameraError.PermissionDenied:
            message = t`Prístup zamietnutý, prosím povoľte prehliadaču prístup ku kamere`
            break
        default:
            message = t`Pri pokuse o prístup ku kamere nastala neočakávaná chyba`
    }

    useEffect(() => {
        addVerificationJournalData(
            verificationToken,
            EVerificationJournalMessage.cameraIssue,
            {
                error: cameraError,
                message: message
            }
        )
    }, [message, cameraError, verificationToken])

    return (
        <>
            <MainNavigation/>
            <div className="box_video nocamera">
                <div className="box_video_inner">
                    <div className="box_video_content">

                        <img src="/img/layout/novideo.png" alt="" className="box_video_image" />
                        
                        <div className="box_video_cover"></div>
                        <style>
                            {`
                                .box_video{
                                    --h-per: 56%;
                                    --w-per: 56%;
                                    --w-m-per: 88%;
                                    --h-m-per: 44%;
                                }
                            `}
                        </style>

                        <div className="box_video_frame">
                        </div>

                        <div className="box_video_bottom">
                            <div className="box_video_semi box_video_text w100p">
                                <span className="icon type2">
                                    <svg viewBox="0 0 21 21" className="sicofill" xmlns="http://www.w3.org/2000/svg">
                                        <use href="/img/layout/icons.svg#s_cross" />
                                    </svg>
                                </span>
                                <span>{message}</span>
                            </div>
                        </div>

                        <CameraSwitch/>
                    </div>
                </div>            
            </div>
           
            <div className="main_bottom">
                <div className="box_buttons">
                    <div className="box_buttons_left">
                        <button 
                            className="btn_basic more"
                            onClick={() => {
                                dispatch(setSystemStore(ESystemActions.SET_CAMERA_ERROR, null))
                                dispatch(setStateStore(EStateActions.SET_DEVICE_ID, null))
                                dispatch(setStateStore(EStateActions.GO_PREV, null))
                            }}
                        >
                            <span className="arrow">
                                <svg className="sicofill" viewBox="0 0 21 11" xmlns="http://www.w3.org/2000/svg">
                                    <use href="/img/layout/icons.svg#s_ar_l" />
                                </svg>   
                            </span>
                        </button>
                    </div>

                    {selectedCard?.allowManual?(
                        <div className="box_buttons_right">
                            <button 
                                className="btn_basic"
                                onClick={() => {
                                    dispatch(setStateStore(EStateActions.GO_MANUAL, null))
                                }}
                            >
                                <span className="text">
                                    <Trans>Požiadať o manuálne overenie</Trans>
                                </span>
                            </button>
                        </div>
                    ):null}
                </div>
            </div>
        </>
    )
}
