import { useAppSelector, useAppDispatch } from '../../../store/index';
import { EStateActions, setStateStore } from '../../../reducers/state';
import { motion } from 'framer-motion';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { translateText } from '../../helpers/utils';
import { useCallback, useEffect } from 'react';
import { EVerificationJournalMessage, ICardConfigData } from '../../../types';
import { addVerificationJournalData } from '../../../reducers/api/addVerificationJournalData';

interface IProps {}

export const ScreenSelectCard: React.FC<IProps> = () => {
    const dispatch = useAppDispatch()
    const lingui = useLingui()

    const currentVerification = useAppSelector(store => store.stateStore.currentVerification)
    const verificationToken = useAppSelector(store => store.stateStore.verificationToken)

    const selectCard = useCallback((key: string, card: ICardConfigData) => {
        dispatch(setStateStore(EStateActions.SET_SELECTED_CARD, card))
        dispatch(setStateStore(EStateActions.SET_SELECTED_CARD_KEY, key))
        dispatch(setStateStore(EStateActions.GO_NEXT, null))

        addVerificationJournalData(
            verificationToken,
            EVerificationJournalMessage.selectCard,
            {
                card: key
            }
        )
    }, [dispatch, verificationToken])

    useEffect(() => {
        const config = currentVerification?.configurations?.process
        const keys = config?Object.keys(config):[]

        if(currentVerification && keys.length === 1) {
            selectCard(keys[0], currentVerification.configurations.process[keys[0]])
        }
    }, [selectCard, currentVerification])

    if(!currentVerification) {
        return null
    }

    const configKeys = currentVerification.configurations.process?Object.keys(currentVerification.configurations.process):[]

    return (
        <div className="block_center_vert wrapper pdt-50 pdb-50">
            <h2 className="text_center title_h1 mgt-15 title-select">
                <Trans>Začnite výberom dokladu, ktorý chcete overiť</Trans>
            </h2>

            <div className="grid mgt-20 justify-center">
                {
                    configKeys.map( (key,i) => {
                        const c = currentVerification.configurations.process[key]

                        return (
                            <motion.div 
                                key={key} 
                                initial={{
                                    opacity: 0,
                                    y: -10
                                }}
                                animate={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: .5,
                                        delay: i*0.3
                                    }
                                }}
                                className={`item_type col2 col1_mdm offset`}
                                onClick={() => {
                                    selectCard(key, c)
                                }}
                            >
                                <div 
                                    className="icon2" 
                                    style={{
                                        color: c.colorIcon,
                                        backgroundColor: c.colorBackground
                                    }}
                                >
                                    <svg viewBox="0 0 32 24" className="sicofill" xmlns="http://www.w3.org/2000/svg"><use href="/img/layout/icons.svg#s_pass"></use></svg>
                                </div>
                                <h3 className="title_h2 mgt-10">{translateText(c.labels, lingui.i18n.locale)} ({c.country})</h3>
                            </motion.div>
                        )
                    })
                }
            </div>
        </div>
    )
}
