import React from 'react'
import { useIsMobileDevice } from '../../hooks/useIsMobileDevice';
import FloatingNotificationsContainer from './NotificationsContainer';
import Animation from './Animation';
import LanguageSwitch from './LanguageSwitch';
import { useAppSelector } from '../../../store';

interface IProps {
    children: React.ReactNode
}

const LayoutContainer: React.FC<IProps> = (props) => {
    const verification = useAppSelector(store => store.stateStore.currentVerification)

    const isMobile = useIsMobileDevice()
    
    let imageUrl = '/img/layout/trustsnap.svg'

    if(verification && verification.configurations.visual.visualSettings.logoUrl) {
        imageUrl = verification.configurations.visual.visualSettings.logoUrl
    }

    return (
        <div className={`container ${isMobile ? 'mobile_device' : ''}`}>
            <FloatingNotificationsContainer />
            
            <header className="header">
                <Animation type='vertical_top'>
                    <a href="/" title="" className="header_logo">
                        <img src={imageUrl} alt="TrustSnap.com"/>
                    </a>
                </Animation>

                <LanguageSwitch/>
            </header>

            {props.children}

            <footer className="footer">
                {/* <a href="#" title="" className="footer_logo">
                    <img src="/img/layout/badge.png" alt="" />
                </a> */}
            </footer>
        </div>
    )    
}

export default LayoutContainer
