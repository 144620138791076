import { INotificationFull } from '../../../types';


interface IProps {
    data: INotificationFull,
    deleteFunction: () => any
}

const Notification = (props: IProps) => {
    const { data, deleteFunction } = props

    let icon = 's_at'
    let iconClass = 'type2'

    if(data.type === 'error') {
        icon = 's_cross'
        iconClass = 'type3'
    }

    if(data.type === 'warning') {
        icon = 's_check'
        iconClass = ''
    }

    return (
        <div
            className={"floating-notification " + data.type}
            onClick={() => {
                console.log('notification clicked ', data.id)
                deleteFunction()
            }}
        >
            <span className={`icon ${iconClass}`}>
                <svg viewBox="0 0 21 21" className="sicofill" xmlns="http://www.w3.org/2000/svg">
                    <use href={`/img/layout/icons.svg#${icon}`} />
                </svg>
            </span>
            <span>
                {data.text}
            </span>
        </div>
    )
};

export default Notification
