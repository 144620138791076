import { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux'
import { store } from './store';
import { Main } from './Main';
import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core';
import { messages as sk } from './locales/sk/messages.js'
import { messages as en } from './locales/en/messages.js'
import { sk as pluralsSk } from 'make-plural/plurals'
import { en as pluralsEn } from 'make-plural/plurals'


export const currentLanguage = 'en'

i18n.load('sk', sk)
i18n.load('en', en)

i18n.loadLocaleData({
    sk: { plurals: pluralsSk },
    en: { plurals: pluralsEn }
})
i18n.activate(currentLanguage)

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <I18nProvider i18n={i18n}>
            <Main/>
        </I18nProvider>
    </Provider>
)
