import { createStore, applyMiddleware, combineReducers } from 'redux';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { loadingReducer, ILoadingStore } from '../reducers/loading';
import { notificationsReducer, INotificationsStore } from '../reducers/notifications';
import { systemReducer, ISystemStore } from '../reducers/system';
import { IWSStore, wsReducer } from '../reducers/ws';
import reduxWebsocket from '@giantmachines/redux-websocket';
import { TypedUseSelectorHook } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { IStateStore, stateStore } from '../reducers/state';
import { configureStore } from '@reduxjs/toolkit';

export interface IApplicationStore {
    systemStore: ISystemStore,
    stateStore: IStateStore,
    loadingStore: ILoadingStore,
    notificationsStore: INotificationsStore,
    wsStore: IWSStore
}

const rootReducer = combineReducers({
    systemStore: systemReducer,
    stateStore: stateStore,
    loadingStore: loadingReducer,
    notificationsStore: notificationsReducer,
    wsStore: wsReducer
})

const reduxWebsocketMiddleware = reduxWebsocket()

let store = createStore(rootReducer, applyMiddleware(promise, thunk, reduxWebsocketMiddleware))

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export { store }
