import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';
import { setLoadingStore, ELoadingActions } from '../loading';
import { addFloatingNotification } from '../notifications';
import { IBox } from '../../types';
import { logDebug, logError } from '../../js/helpers/logger';
import { t } from '@lingui/macro';

const uploadClientFile = async (
    formData: FormData | string,
    isBase64?: boolean,
    fileExtension?: string,
    cropBox?: IBox
): Promise<string> => {
    store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_UPLOAD_FILE, true))

    let headers: any = {}

    if (isBase64) {
        headers['Content-Type'] = 'application/json'
    }

    return fetch(config.api.filesUrl + '/upload' + (isBase64?'/base64':''), {
        method: "POST",
        headers: headers,
        body: (isBase64 && fileExtension)?JSON.stringify({
            file: formData,
            isBase64: true,
            fileExtension: fileExtension,
            cropBox: cropBox
        }):formData,
    })
        .then(handleErrors)
        .then(res => res.json())
        .then(res => {
            store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_UPLOAD_FILE, false))
            logDebug('Upload client file response:', res)
            return res.name
        })
        .catch((error: any)  => {
            store.dispatch(setLoadingStore(ELoadingActions.SET_LOADING_UPLOAD_FILE, false))
            store.dispatch(addFloatingNotification({
                text: t`Nepodarilo sa nahrať súbor. Kontaktujte prosím administrátora.`,
                type: 'error',
                showBell: true
            }))
            logError(error)

            return ''
        });
}

export default uploadClientFile
