import React from 'react'
import { FieldValues, UseFormHandleSubmit } from 'react-hook-form';
import { IFileUpload, IFile } from '../../../types';
import { useAppSelector, useAppDispatch } from '../../../store/index';
import { useMemo } from 'react';
import uploadClientFile from '../../../reducers/api/uploadClientFile';
import { v4 as uuidv4 } from 'uuid';
import { setStateStore, EStateActions } from '../../../reducers/state';
import { Trans } from '@lingui/macro';
import { storeVerificationDataResults } from '../../../reducers/api/storeVerificationDataResults';

interface IProps {
    handleSubmit: UseFormHandleSubmit<FieldValues>,
    isManual: boolean,
    confirmed: boolean,
    uploadFiles: IFileUpload[],
    uploadFace: IFile|null
}

export const ButtonSubmitData: React.FC<IProps> = ({ 
    handleSubmit,
    isManual,
    confirmed,
    uploadFiles,
    uploadFace
 }) => {
    const dispatch = useAppDispatch()

    const verification = useAppSelector(store => store.stateStore.currentVerification)
    const cardSides = useAppSelector(store => store.stateStore.cardSidesData)
    const faceFilename = useAppSelector(store => store.stateStore.faceFileName)
    const selectedCard = useAppSelector(store => store.stateStore.selectedCard)
    const verificationToken = useAppSelector(store => store.stateStore.verificationToken)
    
    const needFace = useMemo(() => selectedCard?.sides.filter(s => s.serverSettings.hasFace)?true:false, [selectedCard])

    const disabled = useMemo(() => {

        if(needFace && faceFilename === null) {
            return true
        }

        if(
            verification &&
            cardSides.filter(cs => cs.filename === null).length > 0
        ) {
            return true
        }

        return false
    }, [cardSides, needFace, verification, faceFilename])

    const uploadFile = async (
        file: IFile|null, 
        fileName: string, 
        setFileName: (val: string) => void
    ): Promise<boolean> => {
        if (fileName) {
            return true
        }

        if (file) {
            let data = new FormData()

            data.append('file', file, file.name)

            let response = await uploadClientFile(data, false)

            setFileName(response)

            return true
        }

        return false
    }

    const sendData = (
        data: FieldValues
    ) => {

        storeVerificationDataResults(
            verificationToken,
            data
        )
    }

    const onSubmit = (data: FieldValues) => {
        console.log(uploadFiles, uploadFace)
        if (isManual) {
            Promise.all([
                ...uploadFiles.map(uf => {
                    return uploadFile(
                        uf.selectedFile, 
                        uuidv4(), 
                        (val) => {
                            dispatch(setStateStore(EStateActions.SET_UPLOADED_FILENAME, {
                                sideType: uf.sideType,
                                filename: val
                            }))
                        }
                    )
                })
            ]).then(async (values: boolean[]) => {
                let needFaceRes = true

                if(needFace && uploadFace) {
                    needFaceRes = await uploadFile(
                        uploadFace,
                        uuidv4(), 
                        (val) => {
                            dispatch(setStateStore(EStateActions.SET_FACE_FILENAME, val))
                        }
                    )
                }

                if (confirmed && values.every(val => val) && needFaceRes) {
                    sendData(data)
                }
            })
        } else {
            if(confirmed) {
                sendData(data)
            }
        }
    }

    return (
        <button
            type='submit'
            className="btn_basic bordered"
            disabled={disabled}
            onClick={handleSubmit((data) => {
                onSubmit(data)
            })}
        >
            <span className="text"><Trans>Odoslať</Trans></span>
        </button>
    )
}
