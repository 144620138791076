import { Reducer } from 'redux'
import { IVerificationConfig, EScreen, ICardConfigData, ECardSideType, IOCRResponseText } from '../types';
import { ECardBackendType } from '../config';

export interface IPath {
    id: number,
    screen: EScreen,
    sideType?: ECardSideType
}

export interface IStateStore {
    currentVerification?: IVerificationConfig | null,
    verificationToken: string|null,
    deviceId: string|null,
    cardSidesData: {
        sideType: ECardSideType,
        filename: string|null
    }[],
    faceFileName: string|null,
    croppedFaceFileName: string|null,
    faceDescriptors: Float32Array[],
    livenessSimilarity: number,
    selectedCard: ICardConfigData|null,
    selectedCardKey: string|null,
    readedTexts: IOCRResponseText[],
    readedCardType: ECardBackendType|null,
    navigation: IPath[],
    currentNavId: number,
    agreedBegin: boolean,
    similarity: number | null
}

export enum EStateActions {
    SET_CURRENT_VERIFICATION = 'SET_CURRENT_VERIFICATION',
    SET_VERIFICATION_TOKEN = 'SET_VERIFICATION_TOKEN',
    RESET_STATE = 'RESET_STATE',
    SET_DEVICE_ID = 'SET_DEVICE_ID',
    SET_SELECTED_CARD = 'SET_SELECTED_CARD',
    SET_SELECTED_CARD_KEY = 'SET_SELECTED_CARD_KEY',
    SET_UPLOADED_FILENAME = 'SET_UPLOADED_FILENAME',
    SET_FACE_FILENAME = 'SET_FACE_FILENAME',
    SET_CROPPED_FACE_FILENAME = 'SET_CROPPED_FACE_FILENAME',
    SET_READED_CARD_TYPE = 'SET_READED_CARD_TYPE',
    SET_TEXTS = 'SET_TEXTS',
    SET_FACE_DESCRIPTORS = 'SET_FACE_DESCRIPTORS',
    SET_NAVIGATION = 'SET_NAVIGATION',
    GO_PREV = 'GO_PREV',
    GO_NEXT = 'GO_NEXT',
    GO_MANUAL = 'GO_MANUAL',
    GO_HOME = 'GO_HOME',
    AGREE_BEGIN = 'AGREE_BEGIN',
    SET_SIMILARITY = 'SET_SIMILARITY'
}

const initialState: IStateStore = {
    currentVerification: null,
    verificationToken: null,
    deviceId: null,
    cardSidesData: [],
    faceFileName: null,
    croppedFaceFileName: null,
    faceDescriptors: [],
    livenessSimilarity: 0,
    selectedCard: null,
    selectedCardKey: null,
    readedTexts: [],
    readedCardType: null,
    navigation: [{
        id: 0,
        screen: EScreen.start
    }],
    currentNavId: 0,
    agreedBegin: false,
    similarity: null
}

export function setStateStore(type: EStateActions, value: any) {
    return { type: type, payload: value}
}

const reducer: Reducer<IStateStore> = (state = initialState, action) => {
    switch (action.type) {
        case EStateActions.RESET_STATE: 
            state = {
                ...state,
                ...initialState
            }
            break
        case EStateActions.SET_DEVICE_ID:
            state = {
                ...state,
                deviceId: action.payload
            }
            break
        case EStateActions.SET_VERIFICATION_TOKEN:
            state = {
                ...state,
                verificationToken: action.payload
            }
            break
        case EStateActions.SET_CURRENT_VERIFICATION:
            let modifiedVerification: IVerificationConfig | null | undefined = action.payload 

            if(modifiedVerification) {
                modifiedVerification.configurations.visual.visualSettings.secondaryColor = '#EBF0F0'
                modifiedVerification.configurations.visual.visualSettings.backgroundColor = '#F7F7F7'
                modifiedVerification.configurations.visual.visualSettings.logoUrl = '/img/01J5R5YP5GFKWEDD3TQQXB6ZD5.png'
            }

            state = {
                ...state,
                currentVerification: modifiedVerification
            }
            break
        case EStateActions.SET_SELECTED_CARD:
            let card: ICardConfigData|null = action.payload

            state = {
                ...state,
                selectedCard: card,
                cardSidesData: card?card.sides.map(s => {
                    return {
                        sideType: s.type,
                        filename: null
                    }
                }):[]
            }
            break
        case EStateActions.SET_SELECTED_CARD_KEY:
            state = {
                ...state,
                selectedCardKey: action.payload
            }
            break
        case EStateActions.SET_UPLOADED_FILENAME:
            state = {
                ...state,
                cardSidesData: state.cardSidesData.map(cs => {
                    return cs.sideType === action.payload.sideType?
                        {
                            ...cs,
                            filename: action.payload.filename
                        }:
                        cs
                })
            }
            break
        case EStateActions.SET_FACE_FILENAME:
            state = {
                ...state,
                faceFileName: action.payload
            }
            break
        case EStateActions.AGREE_BEGIN:
            state = {
                ...state,
                agreedBegin: action.payload
            }
            break
        case EStateActions.SET_TEXTS:
            let newTexts = [...state.readedTexts]

            action.payload.forEach((t: IOCRResponseText) => {
                let was = false

                newTexts.forEach(nt => {
                    if (nt.key === t.key) {
                        nt.value = t.value
                        was = true
                    }
                })

                if(!was) {
                    newTexts.push(t)
                }
            })

            state = {
                ...state,
                readedTexts: newTexts
            }
            break
        case EStateActions.SET_READED_CARD_TYPE:
            state = {
                ...state,
                readedCardType: action.payload
            }
            break
        case EStateActions.SET_CROPPED_FACE_FILENAME:
            state = {
                ...state,
                croppedFaceFileName: action.payload
            }
            break
        case EStateActions.SET_FACE_DESCRIPTORS:
            state = {
                ...state,
                faceDescriptors: action.payload
            }
            break
        case EStateActions.SET_NAVIGATION:
            state = {
                ...state,
                navigation: action.payload
            }
            break
        case EStateActions.SET_SIMILARITY:
            state = {
                ...state,
                similarity: action.payload
            }
            break
        case EStateActions.GO_NEXT: 
            let nextId = state.currentNavId+1

            state = {
                ...state,
                currentNavId: nextId
            }
            break
        case EStateActions.GO_PREV:
            let prevId = state.currentNavId-1

            const objConfigs = state.currentVerification?.configurations?.process

            if((state.navigation.find(s => s.id === prevId)?.screen === EScreen.cardSelect) && (objConfigs && (Object.keys(objConfigs)).length === 1)) {
                prevId = prevId-1
            }

            state = {
                ...state,
                currentNavId: prevId
            }
            break
        case EStateActions.GO_MANUAL:
            let manual = state.navigation.find(n => n.screen === EScreen.manual)

            state = {
                ...state,
                currentNavId: typeof manual !== 'undefined'?manual.id:0
            }
            break
        case EStateActions.GO_HOME:
            let foundStart = state.navigation.find(n => n.screen === EScreen.start)
            state = {
                ...state,
                currentNavId: typeof foundStart !== 'undefined'?foundStart.id:0
            }
            break

        }

    return state
}

export { reducer as stateStore }
